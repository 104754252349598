import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'
import { AnyCnameRecord } from 'dns'

export interface IAuthSliderState {
    status: string
    email: string
    password?: string
    cognitoUser: AnyCnameRecord
    isActivation: boolean
    redirectRoute: string
    errors: any
    loginModalVisible: boolean
    eventChanged: boolean
    loggingIn: boolean
}

const initialState: IAuthSliderState = {
    status: STATUS.IDLE,
    email: '',
    password: '',
    cognitoUser: null,
    isActivation: false,
    redirectRoute: '',
    errors: null,
    loginModalVisible: false,
    eventChanged: false,
    loggingIn: false
}

const authSlider = createSlice({
    name: 'ui/auth-slider',
    initialState,
    reducers: {
        setLoggedInInfo(state, action) {
            for (const item of Object.keys(action.payload)) {
                state[item] = action.payload[item]
            }
        }
    }
})

export const authSliderActions = authSlider.actions
export default authSlider.reducer

// https://redux-toolkit.js.org/api/createSlice
