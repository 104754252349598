import { put, takeLatest, take, select } from 'redux-saga/effects'
import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { RootState } from '@app-store/store'

interface TaskAction extends Action {
    payload: {
        id: string
    }
}

const { onEditRequest, onEditFailure, onEditSuccess } = rootActions.companies.contactCompany
const { onRequest: getPreSignedUploadURL } = rootActions.contacts.getPreSignedUploadURL
const { onRequest: fileUpload } = rootActions.contacts.fileUpload
const { setEmployeesEditorModal } = rootActions.ui.companyProfile
const { onRequest: onOneContactRequest } = rootActions.contacts.oneContact
const { onRequest } = rootActions.contacts.list

function* contactEditorCompanySagaTask({ payload }) {
    try {
        let response
        if (payload?.avatar?.fileList?.[0]) {
            yield put(getPreSignedUploadURL())
            yield take('hub/contacts/get-presigned-upload-URL/onSuccess')
            const { file } = yield select((store: RootState) => store.contacts.getPreSignedUploadURL.data)
            yield put(fileUpload(payload.avatar.fileList[0]))
            yield take('hub/contacts/file-upload/onSuccess')
            payload.data.avatar = file
        } else if (typeof payload.avatar !== 'string') {
            payload.data.avatar = null
        }
        if (payload.id) {
            response = yield axios.put(`/companies/contacts/${payload.id}`, payload.data)
        } else {
            response = yield axios.post(`/companies/contacts`, payload.data)
        }
        if (response.success) {
            if (typeof payload?.sagaCB?.onSuccess === 'function') {
                payload?.sagaCB?.onSuccess(response.result)
            }
            if (payload.isContactProfile) {
                yield put(onOneContactRequest({ id: payload.id }))
            } else if (!payload.notGetList) {
                yield put(
                    onRequest({
                        query: { company: payload.data.company },
                        addPresignedURL: true,
                        populates: ['batch', { path: 'company', select: 'name' }, 'country', 'city'],
                        needPopulate: true
                    })
                )
            }
            if (payload.id && payload.isContactProfile) {
                yield put(onEditSuccess(response.result))
            }
            yield put(setEmployeesEditorModal({ isOpen: false, data: {} }))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.TradeHub?.Employee_changes_successfully || 'Employee changes successfully.'
            })
        }
    } catch (error) {
        yield put(onEditFailure(error))
        yield payload?.sagaCB?.onError(error)
    }
}

function* contactEditorCompanySaga(): any {
    yield takeLatest<TaskAction>(onEditRequest, contactEditorCompanySagaTask)
}

export default contactEditorCompanySaga
