import { createSlice } from '@reduxjs/toolkit'
import { listUIReducers, expandListReducers } from '../reducers'
import { listUIInitialState } from '@utils/constants/requestConstants'

const meetingWishListsSlice = createSlice({
    name: 'ui/meeting-wish-list',
    initialState: {
        ...listUIInitialState,
        wishlistModal: {
            isOpen: false
        },
        expandedRowKeys: []
    },
    reducers: {
        toggleWishlistModal(state) {
            state.wishlistModal.isOpen = !state.wishlistModal.isOpen
        },
        ...listUIReducers,
        ...expandListReducers,
        onResetFilters(state) {
            state.filters = {}
            state.pureFilters = {}
        }
    }
})

export const meetingWishListsActions = meetingWishListsSlice.actions
export default meetingWishListsSlice.reducer
