import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import cloneDeep from 'lodash/cloneDeep'
import { rootActions } from '@app-store/slices'
import { signupCognitoUser } from '@utils/authenticateService'
import { history } from '@components/App'
import routes from '@utils/constants/routes'
import { ISignupPayload } from '@app-store/slices/auth/signup.slice'

interface TaskAction extends Action, ITask {}
interface ITask {
    payload: ISignupPayload
}

const { onFailure, onSuccess, onRequest } = rootActions.auth.signup

function* fetchSignupSaga({ payload }: TaskAction) {
    try {
        const values = cloneDeep(payload)
        delete values.isMeetingHub
        delete values.isPaymentSignup
        if (values.mobile) {
            delete values.prefix
            values.mobile = '+' + (payload.prefix || '') + values.mobile
        }
        yield signupCognitoUser(values)
        if (payload.isMeetingHub) {
            history.replace({
                pathname: routes.subscription.signup.email_confirm._
            })
        } else if (!payload.isPaymentSignup) {
            history.replace({
                pathname: routes.sign_up.email_confirm._
            })
        }
        if (typeof payload?.sagaCB.onSuccess === 'function') payload?.sagaCB.onSuccess()
        yield put(onSuccess({}))
    } catch (e) {
        yield put(onFailure(e))
    }
}

function* SignupSaga(): any {
    yield takeLatest<TaskAction>(onRequest, fetchSignupSaga)
}

export default SignupSaga
