import { createSlice } from '@reduxjs/toolkit'

export interface IContactsDataState {
    pagination: {
        itemsPerPage: number
        currentPage: number
        total: number
    }
    searchValue: string | null
    filters?: any
    sort?: string
    projection?: any
    needPopulate: boolean
    populates?: any
    selectedRowKeys?: string[]
}

const initialState: IContactsDataState = {
    pagination: {
        itemsPerPage: 10,
        currentPage: 1,
        total: 0
    },
    searchValue: null,
    filters: {},
    sort: '-createdAt',
    projection: [],
    needPopulate: true,
    populates: [],
    selectedRowKeys: []
}

const contactsSlice = createSlice({
    name: 'ui/contacts',
    initialState,
    reducers: {
        onChangePagination(state, acion) {
            state.pagination = { ...state.pagination, ...acion.payload }
        },
        onChangeSearch(state, action) {
            state.searchValue = action.payload
        },
        onChangeTotal(state, action) {
            state.pagination = { ...state.pagination, total: action.payload }
        },
        onChangeSelectedRows(state, action) {
            state.selectedRowKeys = action.payload
        },
        onResetFilters(state) {
            state.filters = {}
        }
    }
})

export const contactsActions = contactsSlice.actions
export default contactsSlice.reducer
