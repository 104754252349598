import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const dashboardCompaniesSlice = createSlice({
    name: 'ui/dashboard-companies',
    initialState: {
        ...listUIInitialState,
        pagination: {
            ...listUIInitialState.pagination,
            itemsPerPage: 6
        },
        populates: [
            { path: 'batch', select: 'batchName' },
            { path: 'country', select: 'name' }
        ]
    },
    reducers: listUIReducers
})

export const dashboardCompaniesActions = dashboardCompaniesSlice.actions
export default dashboardCompaniesSlice.reducer
