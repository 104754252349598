import { combineReducers } from 'redux'
import commonReducer, { commonActions } from '@app-store/slices/ui/common.slice'
import authSliderReducer, { authSliderActions } from '@app-store/slices/ui/authSlider.slice'
import emailCampaignReducer, { emailCampaignActions } from '@app-store/slices/ui/eventEmailCampaign'
import settingsReducer, { settingsActions } from '@app-store/slices/ui/settings.slice'
import meetingRequestReducer, { meetingRequestActions } from '@app-store/slices/ui/meetingRequest.slice'
import itpReducer, { itpActions } from '@app-store/slices/ui/itp.slice'
import selectMatchListReducer, { selectMatchListActions } from '@app-store/slices/ui/selectMatchList.slice'
import companyProfileReducer, { companyProfileActions } from '@app-store/slices/ui/companyProfile.slice'
import wishListReducer, { wishListActions } from '@app-store/slices/ui/wishlist.slice'
import directoryReducer, { directoryActions } from '@app-store/slices/ui/directory.slice'
import productReducer, { productActions } from '@app-store/slices/ui/product.slice'
import serviceReducer, { serviceActions } from '@app-store/slices/ui/service.slice'
import chatReducer, { chatActions } from '@app-store/slices/ui/chat.slice'
import wishCampaignReducer, { wishCampaignActions } from '@app-store/slices/ui/wishCampaign.slice'
import outgoingMeetingRequestsReducer, {
    outgoingMeetingRequestsActions
} from '@app-store/slices/ui/outgoingMeetingRequests.slice'
import userReducer, { userActions } from '@app-store/slices/ui/user.slice'
import contactReducer, { contactActions } from '@app-store/slices/ui/contact.slice'
import momentumReducer, { momentumActions } from '@app-store/slices/ui/momentum.slice'
import surveyReducer, { surveyActions } from '@app-store/slices/ui/survey.slice'
import crmIntegrationReducer, { crmIntegrationActions } from '@app-store/slices/ui/crmIntegration.slice'
import matchesByTokenReducer, { matchesByTokenActions } from '@app-store/slices/ui/matchesByToken.slice'
import scansReducer, { scansActions } from '@app-store/slices/ui/scans.slice'
import incomingMeetingRequestsReducer, {
    incomingMeetingRequestsActions
} from '@app-store/slices/ui/incomingMeetingRequests.slice'
import subscriptionReducer, { subscriptionActions } from '@app-store/slices/ui/subscription.slice'
import groupsReducer, { groupsActions } from '@app-store/slices/ui/groups.slice'
import matchListViewReducer, { matchListViewActions } from './matchListView.slice'
import matchListResponsesReducer, { matchListResponsesActions } from './matchListResponses.slice'
import matchListMeetingsReducer, { matchListMeetingsActions } from './matchListMeetings.slice'
import matchListsReducer, { matchListsActions } from './matchLists.slice'
import sharedListResponsesReducer, { sharedListResponsesActions } from './sharedListResponses.slice'
import meetingWishListsReducer, { meetingWishListsActions } from './meetingWishLists.slice'
import meetingAgendaReducer, { meetingAgendaActions } from './meetingAgenda.slice'
import myAgendaReducer, { myAgendaActions } from './myAgenda.slice'
import companyMeetingsReducer, { companyMeetingsActions } from './companyMeetings.slice'
import companyIncomingRequestsReducer, { companyIncomingRequestsActions } from './companyIncomingRequests.slice'
import companyOutgoingRequestsReducer, { companyOutgoingRequestsActions } from './companyOutgoingRequests.slice'
import localizationReducer, { localizationActions } from './localization.slice'
import usersReducer, { usersActions } from './users.slice'
import hallReducer, { hallActions } from '@app-store/slices/ui/hall.slice'
import badgeReducer, { badgeActions } from './badge.slice'
import companyStatisticsReducer, { companyStatisticsActions } from './companyStatistics.slice'
import templatesReducer, { templatesActions } from './templates.slice'
import unassignedCompaniesListReducer, { unassignedCompaniesListActions } from './unassignedCompanies.slice'
import unassignedContactsListReducer, { unassignedContactsListActions } from './unassignedContacts.slice'
import publishedCompaniesListReducer, { publishedCompaniesListActions } from './publishedCompanies.slice'
import publishedContactsListReducer, { publishedContactsListActions } from './publishedContacts.slice'
import internalPublishedCompaniesListReducer, {
    internalPublishedCompaniesListActions
} from './internalPublishedCompanies.slice'
import internalPublishedContactsListReducer, {
    internalPublishedContactsListActions
} from './internalPublishedContacts.slice'
import assignedCompaniesListReducer, { assignedCompaniesListActions } from './assignedCompanies.slice'
import assignedContactsListReducer, { assignedContactsListActions } from './assignedContacts.slice'
import editListReducer, { editListActions } from './editList.slice'
import generalReducer, { generalActions } from './general.slice'
import rfqsReducer, { rfqsActions } from './rfqs.slice'
import quotationsReducer, { quotationsActions } from './quotations.slice'
import sendQuoteReducer, { sendQuoteActions } from './sendQuote.slice'
import incomingRfqsReducer, { incomingRfqsActions } from './incomingRfqs.slice'
import outgoingRfqsReducer, { outgoingRfqsActions } from './outgoingRfqs.slice'
import incomingQuotationsReducer, { incomingQuotationsActions } from './incomingQuotations.slice'
import outgoingQuotationsReducer, { outgoingQuotationsActions } from './outgoingQuotations.slice'
import companySubscriptionsReducer, { companySubscriptionsActions } from './companySubscriptions.slice'
import ordersReducer, { ordersActions } from './orders.slice'
import demosReducer, { demoActions } from './demos.slice'
import otpLoginReducer, { otpLoginActions } from './otpLogin.slice'
import transactionsReducer, { transactionsActions } from './transactions.slice'
import auditTrailReportingReducer, { auditTrailReportingActions } from './auditTrailReporting.slice'
import analyticsReducer, { analyticsActions } from '@app-store/slices/ui/analytics.slice'
import auditTrailReducer, { auditTrailActions } from '@app-store/slices/ui/auditTrail.slice'
import linkedInAccountReducer, { linkedInAccountActions } from '@app-store/slices/ui/linkedInAccount.slice'
import groupReducer, { groupActions } from '@app-store/slices/ui/group.slice'
import callLogReducer, { callLogActions } from '@app-store/slices/ui/callLog.slice'
import newFeatureReducer, { newFeatureActions } from '@app-store/slices/ui/newFeature.slice'
import moduleReducer, { moduleActions } from '@app-store/slices/ui/module.slice'
import feedbackListReducer, { feedbackListActions } from '@app-store/slices/ui/feedbackList.slice'
import matchingSelectionContactsReducer, {
    matchingSelectionContactsActions
} from '@app-store/slices/ui/matchingSelectionContacts'
import matchingSelectionCompaniesReducer, {
    matchingSelectionCompaniesActions
} from '@app-store/slices/ui/matchingSelection.slice'
import labelTagReducer, { labelTagActions } from '@app-store/slices/ui/labelTag.slice'
import contactScanReducer, { contactScanActions } from '@app-store/slices/ui/contactScans.slice'
import companyMatchlistsReducer, { companyMatchlistsActions } from './companyMatchlists.slice'
import meetingRequestsListReducer, { meetingRequestsListActions } from '@app-store/slices/ui/meetingRequestList.slice'
import qrReducer, { qrActions } from '@app-store/slices/ui/qr.slice'
import userActivityLogReducer, { userActivityLogActions } from '@app-store/slices/ui/userActivityLog.slice'
import featureReducer, { featureActions } from '@app-store/slices/ui/feature.slice'
import featureEndpointReducer, { featureEndpointActions } from '@app-store/slices/ui/featureEndpoint.slice'
import manageSubscriptionReducer, { manageSubscriptionActions } from '@app-store/slices/ui/manageSubscription.slice'
import featureManageSubscriptionReducer, {
    featureManageSubscriptionActions
} from '@app-store/slices/ui/featureManageSubscription.slice'
import usersOptionsManageSubscriptionReducer, {
    usersOptionsManageSubscriptionActions
} from '@app-store/slices/ui/usersOptionsManageSubscription.slice'
import settersOptionsManageSubscriptionReducer, {
    settersOptionsManageSubscriptionActions
} from '@store/slices/ui/settersOptionsManageSubscription.slice'
import subscriptionTeamReducer, { subscriptionTeamActions } from '@app-store/slices/ui/subscriptionTeam.slice'
import calendarReducer, { calendarActions } from '@store/slices/ui/calendar.slice'
import companySubscriptionsUsersReducer, { companySubscriptionsUsersActions } from './companySubscriptionsUsers.slice'
import subscriptionPaymentReducer, { subscriptionPaymentActions } from '@app-store/slices/ui/subscriptionPayment.slice'
import eventSubscriptionsReducer, { eventSubscriptionsActions } from './eventSubscriptions.slice'
import subscriptionInvoicesReducer, { subscriptionInvoicesActions } from '@store/slices/ui/subscriptionInvoices.slice'
import outgoingRfqInviteSuppliersReducer, {
    outgoingRfqInviteSuppliersActions
} from '@store/slices/ui/outgoingRfqInviteSuppliers.slice'
import orderHistoryReducer, { orderHistoryActions } from '@store/slices/ui/orderHistory.slice'
import orderPaymentHistoryReducer, { orderPaymentHistoryActions } from '@store/slices/ui/orderPaymentHistory.slice'
import orderTransactionHistoryReducer, {
    orderTransactionHistoryActions
} from '@store/slices/ui/orderTransactionHistory.slic'
import eventProfileReducer, { eventProfileActions } from '@store/slices/ui/eventProfile.slice'
import statusStatisticsReducer, { statusStatisticsActions } from '@store/slices/ui/statusStatistics.slice'
import contactsReducer, { contactsActions } from './contacts.slice'
import duplicateCompaniesListReducer, { duplicateCompaniesListActions } from './duplicateCompaniesList.slice'
import categoriesReducer, { categoriesActions } from '@store/slices/ui/categories.slice'
import perfectMatchReducer, { perfectMatchActions } from '@store/slices/ui/perfectMatch.slice'
import contactCalendarReducer, { contactCalendarActions } from '@store/slices/ui/contactCalendar.slice'
import oneSideSelectReducer, { oneSideSelectActions } from '@store/slices/ui/oneSideSelect.slice'
import oneSideSelectDetailsReducer, { oneSideSelectDetailsActions } from '@store/slices/ui/oneSideSelectDetails.slice'
import dashboardContactsReducer, { dashboardContactsActions } from '@store/slices/ui/dashboardContacts'
import dashboardCompaniesReducer, { dashboardCompaniesActions } from '@store/slices/ui/dashboardCompanies'

export const uiReducer = combineReducers({
    authSlider: authSliderReducer,
    common: commonReducer,
    emailCampaign: emailCampaignReducer,
    settings: settingsReducer,
    meetingRequest: meetingRequestReducer,
    meetingRequestList: meetingRequestsListReducer,
    directory: directoryReducer,
    products: productReducer,
    services: serviceReducer,
    chat: chatReducer,
    outgoingMeetingRequests: outgoingMeetingRequestsReducer,
    companyProfile: companyProfileReducer,
    wishCampaign: wishCampaignReducer,
    wishList: wishListReducer,
    itp: itpReducer,
    user: userReducer,
    contact: contactReducer,
    momentum: momentumReducer,
    survey: surveyReducer,
    crmIntegration: crmIntegrationReducer,
    selectMatchList: selectMatchListReducer,
    matchesByToken: matchesByTokenReducer,
    scans: scansReducer,
    incomingMeetingRequests: incomingMeetingRequestsReducer,
    subscription: subscriptionReducer,
    groups: groupsReducer,
    matchListView: matchListViewReducer,
    matchListResponses: matchListResponsesReducer,
    matchListMeetings: matchListMeetingsReducer,
    matchLists: matchListsReducer,
    sharedListResponses: sharedListResponsesReducer,
    meetingWishLists: meetingWishListsReducer,
    meetingAgenda: meetingAgendaReducer,
    myAgenda: myAgendaReducer,
    companyMeetings: companyMeetingsReducer,
    companyIncomingRequests: companyIncomingRequestsReducer,
    companyOutgoingRequests: companyOutgoingRequestsReducer,
    localization: localizationReducer,
    users: usersReducer,
    hall: hallReducer,
    badges: badgeReducer,
    companyStatistics: companyStatisticsReducer,
    templates: templatesReducer,
    unassignedCompanies: unassignedCompaniesListReducer,
    unassignedContacts: unassignedContactsListReducer,
    publishedCompanies: publishedCompaniesListReducer,
    publishedContacts: publishedContactsListReducer,
    internalPublishedCompanies: internalPublishedCompaniesListReducer,
    internalPublishedContacts: internalPublishedContactsListReducer,
    assignedCompanies: assignedCompaniesListReducer,
    assignedContacts: assignedContactsListReducer,
    editList: editListReducer,
    general: generalReducer,
    rfqs: rfqsReducer,
    quotations: quotationsReducer,
    sendQuote: sendQuoteReducer,
    incomingRfqs: incomingRfqsReducer,
    outgoingRfqs: outgoingRfqsReducer,
    incomingQuotations: incomingQuotationsReducer,
    outgoingQuotations: outgoingQuotationsReducer,
    companySubscriptions: companySubscriptionsReducer,
    demos: demosReducer,
    orders: ordersReducer,
    otpLogin: otpLoginReducer,
    transactions: transactionsReducer,
    auditTrailReporting: auditTrailReportingReducer,
    analytics: analyticsReducer,
    auditTrail: auditTrailReducer,
    linkedInAccounts: linkedInAccountReducer,
    group: groupReducer,
    callLog: callLogReducer,
    newFeature: newFeatureReducer,
    module: moduleReducer,
    feedbackList: feedbackListReducer,
    matchingSelectionContacts: matchingSelectionContactsReducer,
    labelTag: labelTagReducer,
    contactScan: contactScanReducer,
    companyMatchlists: companyMatchlistsReducer,
    qr: qrReducer,
    matchingSelectionCompanies: matchingSelectionCompaniesReducer,
    userActivityLog: userActivityLogReducer,
    feature: featureReducer,
    featureEndpoint: featureEndpointReducer,
    manageSubscription: manageSubscriptionReducer,
    featureManageSubscription: featureManageSubscriptionReducer,
    usersOptionsManageSubscription: usersOptionsManageSubscriptionReducer,
    settersOptionsManageSubscription: settersOptionsManageSubscriptionReducer,
    subscriptionTeam: subscriptionTeamReducer,
    calendar: calendarReducer,
    companySubscriptionsUsers: companySubscriptionsUsersReducer,
    subscriptionPayment: subscriptionPaymentReducer,
    eventSubscriptions: eventSubscriptionsReducer,
    subscriptionInvoices: subscriptionInvoicesReducer,
    outgoingRfqInviteSuppliers: outgoingRfqInviteSuppliersReducer,
    orderHistory: orderHistoryReducer,
    orderPaymentHistory: orderPaymentHistoryReducer,
    orderTransactionHistory: orderTransactionHistoryReducer,
    eventProfile: eventProfileReducer,
    statusStatistics: statusStatisticsReducer,
    contacts: contactsReducer,
    duplicateCompaniesList: duplicateCompaniesListReducer,
    categories: categoriesReducer,
    perfectMatch: perfectMatchReducer,
    contactCalendar: contactCalendarReducer,
    oneSideSelect: oneSideSelectReducer,
    oneSideSelectDetails: oneSideSelectDetailsReducer,
    dashboardContacts: dashboardContactsReducer,
    dashboardCompanies: dashboardCompaniesReducer
})

export const uiActions = {
    common: commonActions,
    authSlider: authSliderActions,
    directory: directoryActions,
    products: productActions,
    services: serviceActions,
    chat: chatActions,
    emailCampaign: emailCampaignActions,
    settings: settingsActions,
    meetingRequest: meetingRequestActions,
    outgoingMeetingRequests: outgoingMeetingRequestsActions,
    companyProfile: companyProfileActions,
    wishList: wishListActions,
    wishCampaign: wishCampaignActions,
    itp: itpActions,
    user: userActions,
    contact: contactActions,
    momentum: momentumActions,
    survey: surveyActions,
    crmIntegration: crmIntegrationActions,
    selectMatchList: selectMatchListActions,
    matchesByToken: matchesByTokenActions,
    scans: scansActions,
    incomingMeetingRequests: incomingMeetingRequestsActions,
    subscription: subscriptionActions,
    groups: groupsActions,
    matchListView: matchListViewActions,
    matchListResponses: matchListResponsesActions,
    matchListMeetings: matchListMeetingsActions,
    matchLists: matchListsActions,
    sharedListResponses: sharedListResponsesActions,
    meetingWishLists: meetingWishListsActions,
    meetingAgenda: meetingAgendaActions,
    myAgenda: myAgendaActions,
    companyMeetings: companyMeetingsActions,
    companyIncomingRequests: companyIncomingRequestsActions,
    companyOutgoingRequests: companyOutgoingRequestsActions,
    localization: localizationActions,
    users: usersActions,
    hall: hallActions,
    badges: badgeActions,
    companyStatistics: companyStatisticsActions,
    templates: templatesActions,
    unassignedCompanies: unassignedCompaniesListActions,
    unassignedContacts: unassignedContactsListActions,
    publishedCompanies: publishedCompaniesListActions,
    publishedContacts: publishedContactsListActions,
    internalPublishedCompanies: internalPublishedCompaniesListActions,
    internalPublishedContacts: internalPublishedContactsListActions,
    assignedCompanies: assignedCompaniesListActions,
    assignedContacts: assignedContactsListActions,
    editList: editListActions,
    general: generalActions,
    rfqs: rfqsActions,
    quotations: quotationsActions,
    sendQuote: sendQuoteActions,
    incomingRfqs: incomingRfqsActions,
    outgoingRfqs: outgoingRfqsActions,
    incomingQuotations: incomingQuotationsActions,
    outgoingQuotations: outgoingQuotationsActions,
    companySubscriptions: companySubscriptionsActions,
    demos: demoActions,
    orders: ordersActions,
    otpLogin: otpLoginActions,
    transactions: transactionsActions,
    auditTrailReporting: auditTrailReportingActions,
    analytics: analyticsActions,
    auditTrail: auditTrailActions,
    linkedInAccounts: linkedInAccountActions,
    group: groupActions,
    callLog: callLogActions,
    newFeature: newFeatureActions,
    module: moduleActions,
    feedbackList: feedbackListActions,
    matchingSelectionContacts: matchingSelectionContactsActions,
    labelTag: labelTagActions,
    contactScan: contactScanActions,
    companyMatchlists: companyMatchlistsActions,
    meetingRequestList: meetingRequestsListActions,
    qr: qrActions,
    matchingSelectionCompanies: matchingSelectionCompaniesActions,
    userActivityLog: userActivityLogActions,
    feature: featureActions,
    featureEndpoint: featureEndpointActions,
    manageSubscription: manageSubscriptionActions,
    featureManageSubscription: featureManageSubscriptionActions,
    usersOptionsManageSubscription: usersOptionsManageSubscriptionActions,
    settersOptionsManageSubscription: settersOptionsManageSubscriptionActions,
    subscriptionTeam: subscriptionTeamActions,
    calendar: calendarActions,
    companySubscriptionsUsers: companySubscriptionsUsersActions,
    subscriptionPayment: subscriptionPaymentActions,
    eventSubscriptions: eventSubscriptionsActions,
    subscriptionInvoices: subscriptionInvoicesActions,
    outgoingRfqInviteSuppliers: outgoingRfqInviteSuppliersActions,
    orderHistory: orderHistoryActions,
    orderPaymentHistory: orderPaymentHistoryActions,
    orderTransactionHistory: orderTransactionHistoryActions,
    eventProfile: eventProfileActions,
    statusStatistics: statusStatisticsActions,
    contacts: contactsActions,
    duplicateCompaniesList: duplicateCompaniesListActions,
    categories: categoriesActions,
    perfectMatch: perfectMatchActions,
    contactCalendar: contactCalendarActions,
    oneSideSelect: oneSideSelectActions,
    oneSideSelectDetails: oneSideSelectDetailsActions,
    dashboardContacts: dashboardContactsActions,
    dashboardCompanies: dashboardCompaniesActions
}
