import { createSlice } from '@reduxjs/toolkit'
import store from 'store'
import { STATUS } from '@features/General'
import { getOffsetTime } from '@features/General/dayjs/hanlers'
const onboardingExhibitor = store.get('onboardingExhibitor')
const initialState = {
    event: {
        _id: ''
    },
    locationItp: {
        selectedArea: [],
        selectedCountries: {}
    },
    selectedTimes: [],
    companySize: [],
    type: '',
    itpType: ['Enterprise'],
    positions: [],
    jobDepartments: [],
    seniorityLevels: [],
    fundingStage: [],
    tags: [],
    sectors: [],
    demandingSectors: [],
    status: {
        profile: STATUS.IDLE
    },
    timezone: getOffsetTime(null)
}

const onboardingExhibitorActionsSlice = createSlice({
    name: 'hub/companies/onboardingExhibitor',
    initialState: onboardingExhibitor || initialState,
    reducers: {
        set(state, action) {
            state[action.payload.type] = action.payload.value
            store.set('onboardingExhibitor', { ...state })
        },
        reset(state) {
            store.remove('onboardingExhibitor')
            Object.assign(state, initialState)
        },
        onRequest(state, action) {
            state.status.company = STATUS.RUNNING
        },
        onUploadImage(state, action) {
            state.status.upload = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status.company = STATUS.READY
            store.remove('onboardingExhibitor')
            for (const key in initialState) {
                state[key] = initialState[key]
            }
        },
        onFailure(state, action) {
            state.status.company = STATUS.ERROR
        },
        onSaveITP(state, action) {
            state.status.itp = STATUS.RUNNING
        },
        onSaveITPSucces(state) {
            state.status.itp = STATUS.READY
        },
        onSaveITPFailure(state) {
            state.status.itp = STATUS.ERROR
        },
        onGetProfile(state) {
            state.status.profile = STATUS.RUNNING
        },
        onGetProfileSucces(state, action) {
            state.status.profile = STATUS.READY
            const data = action.payload
            for (const key in data) {
                state[key] = data[key]
            }
        },
        onGetProfileFailure(state) {
            state.status.profile = STATUS.ERROR
        }
    }
})

export const onboardingExhibitorActions = onboardingExhibitorActionsSlice.actions
export default onboardingExhibitorActionsSlice.reducer
