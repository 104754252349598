import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers, expandListReducers } from '../reducers'

const oneSideSelectSlice = createSlice({
    name: 'ui/one-side-select',
    initialState: {
        ...listUIInitialState,
        expandedRowKeys: []
    },
    reducers: {
        ...listUIReducers,
        ...expandListReducers
    }
})

export const oneSideSelectActions = oneSideSelectSlice.actions
export default oneSideSelectSlice.reducer
