import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const dashboardContactsSlice = createSlice({
    name: 'ui/dashboard-contacts',
    initialState: {
        ...listUIInitialState,
        pagination: {
            ...listUIInitialState.pagination,
            itemsPerPage: 6
        },
        populates: [
            { path: 'batch', select: 'batchName' },
            { path: 'country', select: 'name' }
        ]
    },
    reducers: listUIReducers
})

export const dashboardContactsActions = dashboardContactsSlice.actions
export default dashboardContactsSlice.reducer
