import { LOCALSTORAGE_KEYS } from '@features/General'
import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { setUserRoleCookie } from '@features/Auth'
import { IUserProfilePayload } from '@app-store/slices/users/profile'

interface TaskAction extends Action {
    payload: IUserProfilePayload
}

const { onRequest, onSuccess, onFailure } = rootActions.users.profile

function* userProfileSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/users/profile`, {
            transformRequest: (data, headers) => {
                delete headers['event-id']
                return data
            }
        })
        if (response.success) {
            setUserRoleCookie(response.result.role.title, response.result.role.type)
            localStorage.setItem(LOCALSTORAGE_KEYS.USERINFO, JSON.stringify(response.result))
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* userProfileSaga(): any {
    yield takeLatest<TaskAction>(onRequest, userProfileSagaTask)
}

export default userProfileSaga
