import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import { history } from '@components/App'
import { COOKIE_KEYS, getCookie } from '@features/General'
import { existSubdomain } from '@features/Auth'
import routes from '@utils/constants/routes'
import { rootActions } from '@app-store/slices'
import { RootState } from '@app-store/store'
import { useUser } from '../../../hooks'

function PrivateRoute({ children, component: Component, ...rest }: RouteProps) {
    const hasToken = !isEmpty(getCookie(COOKIE_KEYS.ALA_TOKEN))
    const dispatch = useDispatch()

    useEffect(() => {
        if (!hasToken) history.push(routes.login._)
    }, [])

    useEffect(() => {
        dispatch(rootActions.ui.authSlider.setLoggedInInfo({ loginModalVisible: !hasToken, loggingIn: false }))
    }, [hasToken])
    return (
        <Route
            {...rest}
            render={props =>
                hasToken ? (
                    <>{children || <Component {...props} />}</>
                ) : (
                    <></>
                    // <Redirect
                    //     to={{
                    //         pathname: routes.login._,
                    //         state: { from: props.location }
                    //     }}
                    // />
                )
            }
        />
    )
}

export function FlatRouterProvider({ children, ...rest }: RouteProps) {
    const hasToken = !isEmpty(getCookie(COOKIE_KEYS.ALA_TOKEN))
    const eventId = getCookie(COOKIE_KEYS.ALA_EVENT)
    const { data } = useSelector((state: RootState) => state.events.oneEventByDomain)
    const { user } = useUser()
    const dispatch = useDispatch()

    const redirectionRoute = () => {
        const isAdmin = find(
            user?.eventInfos,
            item => item?.role?.title === 'Admin' && item?.role?.type === 'Organizer'
        )
        const hasValidSubDimain = existSubdomain(data?.branding)
        if ((!hasValidSubDimain && isAdmin) || user?.eventInfos?.length === 0) {
            return routes.organizer.events._
        } else if (!hasValidSubDimain && !eventId) {
            dispatch(rootActions.companies.onboarding.reset())
            return routes.exhibitor.onboarding.events._
        } else {
            return routes.organizer._
        }
    }
    useEffect(() => {
        if (!hasToken || !user) history.push(routes.login._)
    }, [])

    return (
        <Route
            {...rest}
            render={({ location }) => (
                <>
                    {hasToken && user && (
                        <Redirect
                            to={{
                                pathname: redirectionRoute(),
                                state: { from: location }
                            }}
                        />
                    )}
                </>
            )}
        />
    )
}

export default PrivateRoute
