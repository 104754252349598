import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ICreateSubscriptionPayload } from '@app-store/slices/subscriptionPayment/create'

interface TaskAction extends Action {
    payload: ICreateSubscriptionPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.subscriptionPayment.create

function* createPaymentSagaTask({ payload }) {
    try {
        const response = yield axios.post(`/subscriptions/payments`, payload.data)
        if (response.success) {
            yield payload.sagaCB?.onSuccess(response.result)
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        if (typeof payload.sagaCB?.onError === 'function') payload.sagaCB?.onError()
        yield put(onFailure(error))
    }
}

function* createPaymentSaga(): any {
    yield takeLatest<TaskAction>(onRequest, createPaymentSagaTask)
}

export default createPaymentSaga
