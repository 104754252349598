import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import { ILoginPayload } from '@app-store/slices/auth/login.slice'
import { loginCognitoUser } from '@utils/authenticateService'
import store from '@app-store/store'

interface TaskAction extends Action, ITask {}
interface ITask {
    payload: ILoginPayload
}

const { onFailure, onRequest } = rootActions.auth.login

function* fetchLoginSaga({ payload }: TaskAction) {
    try {
        const loginValues = {
            username: payload.email,
            password: payload.password,
            isActivation: payload.isActivation || false
        }
        const response = yield loginCognitoUser(loginValues)
        // yield put(onSuccess(response))
        payload?.sagaCB.onSuccess(response)
    } catch (err) {
        store.dispatch(rootActions.ui.authSlider.setLoggedInInfo({ loggingIn: false }))
        yield put(onFailure(err.message || 'Email or password is incorrect'))
    }
}

function* loginSaga(): any {
    yield takeLatest<TaskAction>(onRequest, fetchLoginSaga)
}

export default loginSaga
