export const generatePassword = (): string => {
    let result = ''
    const length = 8
    const numbers = '0123456789'
    const lowLetters = 'abcdefghijklmnopqrstuvwxyz'
    const upLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const symbols = '!@#$%^&*()'
    const allChars = numbers + lowLetters + upLetters + symbols
    const randomNumber = Math.floor(Math.random() * numbers.length)
    result += numbers.substring(randomNumber, randomNumber + 1)
    const randomLowChar = Math.floor(Math.random() * lowLetters.length)
    result += lowLetters.substring(randomLowChar, randomLowChar + 1)
    const randomUpChar = Math.floor(Math.random() * upLetters.length)
    result += upLetters.substring(randomUpChar, randomUpChar + 1)
    const randomSymbol = Math.floor(Math.random() * symbols.length)
    result += symbols.substring(randomSymbol, randomSymbol + 1)

    for (let i = 0; i <= length - 4; i++) {
        const randomNumber = Math.floor(Math.random() * allChars.length)
        result += allChars.substring(randomNumber, randomNumber + 1)
    }
    return result
}
