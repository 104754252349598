import { Action } from 'redux'
import { IOneEventByDomainPayload } from '@app-store/slices/events/oneEventByDomain'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { put, takeLatest } from 'redux-saga/effects'

interface TaskAction extends Action {
    payload: IOneEventByDomainPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.events.oneEventByDomain

function* oneEventByDomainSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/event/domain/${payload.subdomain}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSuccess(response.result)
        } else {
            yield put(onFailure({}))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}
function* oneEventByDomainSaga(): any {
    yield takeLatest<TaskAction>(onRequest, oneEventByDomainSagaTask)
}

export default oneEventByDomainSaga
