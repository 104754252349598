import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { IGetSummaryPayload } from '@app-store/slices/companySubscriptions/getSummary'

interface TaskAction extends Action {
    payload: IGetSummaryPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.companySubscriptions.getSummary

function* getSummarySagaTask({ payload }: TaskAction) {
    try {
        const params = yield getParams(payload)
        const response = yield axios.get('/subscriptions/company-subscriptions/summary', {
            params: { ...params, retentionType: payload.retentionType || 'Yearly' }
        })

        if (response.success) {
            yield put(onSuccess({ noSaveInStore: payload.noSaveInStore, ...response.result }))
            yield payload.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        if (typeof payload.sagaCB?.onError === 'function') {
            yield payload.sagaCB?.onError()
        }
        yield put(onFailure(error))
    }
}

function* getSummarySaga(): any {
    yield takeLatest<TaskAction>(onRequest, getSummarySagaTask)
}

export default getSummarySaga
