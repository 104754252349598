import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers, expandListReducers } from '../reducers'

const oneSideSelectDetailsSlice = createSlice({
    name: 'ui/one-side-select',
    initialState: {
        ...listUIInitialState,
        expandedRowKeys: []
    },
    reducers: {
        ...listUIReducers,
        ...expandListReducers
    }
})

export const oneSideSelectDetailsActions = oneSideSelectDetailsSlice.actions
export default oneSideSelectDetailsSlice.reducer
